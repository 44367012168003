<template>
  <div class="page-box" id='good-list'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="商品名称" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="供应商" prop="supplyShopId">
          <a-spin class="custom-spin" :spinning="listPartnerLoading" size="small">
            <a-select style='min-width:200px' v-model="queryRef.supplyShopId" placeholder="请选择" allowClear>
              <a-select-option v-for="d in listPartner" :key="d.shopId">
                <span class="custom-select-option">{{d.name}}</span>
              </a-select-option>
            </a-select>
          </a-spin>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click='$router.push("/marketing/goods/add")'>
        添加推广商品
      </a-button>
      <a-button type="primary" ghost :disabled="selectedRowKeys.length === 0" @click='setAllBrokerage'>
        统一设置佣金
      </a-button>
      <a-button type="primary" ghost :disabled="selectedRowKeys.length === 0" @click='handleDeleteProduct($event)'>
        批量删除
      </a-button>
      <p v-if="selectedRowKeys.length">已选{{selectedRowKeys.length}}件商品</p>
    </div>
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.promoteProductId" :loading="loadingRef" @change="changeTablePage" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
      <template #goodsName="record">
        <router-link tag="div" class="goods-info goods" :to="`/goods/goods_detail/${record.productId}`">
          <base-img class="goods-img" :src="record.productImg" width="60" height="60" oss_style="60_60" />
          <a-tooltip :title="record.name">
            <p class="goods-name el2">{{record.name}}</p>
          </a-tooltip>
        </router-link>
      </template>
      <template #operation="record">
        <div class="table-operations">
          <a-button type="link" @click='setSingleBrokerage(record)'>
            佣金设置
          </a-button>
          <a-popconfirm placement="topRight" :title="`确定删除该商品吗？`" ok-text="确定" cancel-text="取消" @confirm="handleDeleteProduct($event,record.promoteProductId)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </base-table>
    <!-- 统一设置佣金 -->
    <a-modal title='佣金设置' width='680px' :maskClosable="false" v-model='setAllBrokerageModal' :confirm-loading='setAllBrokerageConfirmLoading' @ok='setAllBrokerageSubmit'>
      <a-spin :spinning='setAllBrokerageLoading'>
        <div>
          <a-row>
            <a-col :span='3'>佣金类型：</a-col>
            <a-col :span='21'>
              <div style='font-size:14px;color:#000;margin-bottom:4px;'>按比例</div>
              <p style='font-size:12px;color:#999'>此设置为所选<span style='font-weight:blod;color:#000'>{{selectedRowKeys.length}}件</span>商品统一按比例设置佣金</p>
            </a-col>
          </a-row>
          <a-row style='margin-top:12px;'>
            <a-col :span='3'>
              <span style='line-height:54px;'>佣金明细：</span>
            </a-col>
            <a-col :span='21'>
              <a-table :scroll="{x:550}" :rowKey="(record,index) => index" :pagination="false" :columns='setAllBrokerageTableColumns' :data-source="[setAllBrokerageTableData]">
                <template v-for='(value,name) in setAllBrokerageTableData' :slot="name" slot-scope="text,record">
                  <div :key='name' class='flex-a-c'>
                    <a-input v-model="record[name]" suffix='%' @input="checkSetAllBrokerageData"></a-input>
                  </div>
                </template>
              </a-table>
              <p class='tip' v-if='setAllBrokerageTableDataError'>{{setAllBrokerageTableDataError}}</p>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </a-modal>
    <!-- 单商品设置佣金 -->
    <a-modal title='佣金设置' width='920px' :maskClosable="false" v-model='setSingleBrokerageModal' :confirm-loading='setSingleBrokerageConfirmLoading' @ok='setSingleBrokerageSubmit'>
      <a-spin :spinning='setSingleBrokerageLoading'>
        <div>
          <a-row>
            <a-col :span='2'>商品名称：</a-col>
            <a-col :span='22'>
              <div style='font-size:14px;color:#000;margin-bottom:4px;'>{{singleDataBaseForm.name}}</div>
            </a-col>
          </a-row>
          <a-row style='margin-top:12px;'>
            <a-col :span='2'>佣金类型：</a-col>
            <a-col :span='22'>
              <a-radio-group v-model="singleDataBaseForm.shareRatioType">
                <a-radio :value="1">
                  按比例
                </a-radio>
                <a-radio :value="2">
                  固定金额
                </a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <a-row style='margin-top:12px;'>
            <a-col :span='2'>
              <span style='line-height:54px;'>佣金明细：</span>
            </a-col>
            <a-col :span='22'>
              <a-table :scroll="{x:800}" :rowKey="(record,index) => index" :pagination="false" :columns='setSingleBrokerageTableColumns.filter(x=>x.dataIndex.indexOf(singleDataBaseForm.shareRatioType===1?"amount":"ratio") === -1)' :data-source="setSingleBrokerageTableData">
                <template v-for='(item,index) in setSingleBrokerageTableColumns.filter(x=>x.dataIndex.indexOf(singleDataBaseForm.shareRatioType===1?"amount":"ratio") === -1)' :slot="item.dataIndex" slot-scope="text,record">
                  <div :key='index'>
                    <a-input v-if='item.dataIndex.indexOf("ratio")!==-1 && singleDataBaseForm.shareRatioType===1' v-model="record[item.dataIndex]" suffix='%' @input="checkSetSingleBrokerageData"></a-input>
                    <a-input v-else-if='item.dataIndex.indexOf("amount")!==-1 && singleDataBaseForm.shareRatioType===2' v-model="record[item.dataIndex]" suffix='元' @input="checkSetSingleBrokerageData"></a-input>
                    <base-img class="goods-img" v-else-if="item.dataIndex==='skuPic'" :src="record.skuPic" width="60" height="60" oss_style="60_60" />
                    <span v-else>{{text||'-'}}</span>
                  </div>

                </template>
              </a-table>
              <p class='tip' v-if='setSingleBrokerageTableDataError'>{{setSingleBrokerageTableDataError}}</p>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { marketing, common } from '@/api'
import NP from 'number-precision'
const columns = [
  {
    title: '商品名称',
    scopedSlots: {
      customRender: 'goodsName',
    },
  },
  {
    title: '供应商',
    dataIndex: 'shopName',
    scopedSlots: {
      customRender: 'shopName',
    },
  },
  {
    title: '商品单价',
    dataIndex: 'price',
  },
  {
    title: '库存',
    dataIndex: 'stock',
  },
  {
    title: '购买人数量',
    dataIndex: 'buyNum',
  },
  {
    title: '总销量',
    dataIndex: 'sales',
  },
  {
    title: '推广销量',
    dataIndex: 'promoteSales',
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
export default {
  name: 'PageGoodsList',
  setup (props, { root }) {
    const state = reactive({
      listPartner: [],
      selectedRowKeys: [],
      listPartnerLoading: false,
      setAllBrokerageModal: false,
      setAllBrokerageLoading: false,
      setAllBrokerageConfirmLoading: false,
      setAllBrokerageRules: {},
      setAllBrokerageTableColumns: [],
      setAllBrokerageTableData: {},
      setAllBrokerageTableDataError: '',

      setSingleBrokerageModal: false,
      setSingleBrokerageLoading: false,
      setSingleBrokerageConfirmLoading: false,
      setSingleBrokerageRules: {},
      setSingleBrokerageTableColumns: [],
      setSingleBrokerageTableData: [],
      setSingleBrokerageTableDataError: '',
      singleDataBaseForm: {},
    })
    onMounted(async () => {
      getListPartner()
    })
    const formRef = ref(null)
    const queryRef = ref({
      name: '',
      supplyShopId: undefined,
    })
    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(
      marketing.getPromoteProductPage,
      queryRef,
      true,
      (res) =>
        res
          ? res.map((x) => ({
            ...x,
            shopAuthState: typeof x.shopAuthState === 'undefined' ? 0 : x.shopAuthState,
            tradePriceMarkup: x.tradePriceMarkup ? NP.times(x.tradePriceMarkup, 100) : '',
          }))
          : []
    )

    function handleSearch () {
      resetPage()
      refresh()
    }

    function handleReset () {
      formRef.value.resetFields()
      handleSearch()
    }

    function changeTablePage ({ current }) {
      setPage(current)
    }

    async function getListPartner () {
      state.listPartnerLoading = true
      const { data } = await common.getListPartner()
      state.listPartnerLoading = false
      state.listPartner = data || []
      if (
        root.$store.getters['head/getShopInfo'].name &&
        root.$store.getters['head/getShopInfo'].shopId
      ) {
        state.listPartner.push({
          name: root.$store.getters['head/getShopInfo'].name,
          shopId: root.$store.getters['head/getShopInfo'].shopId,
        })
      }
    }
    function onSelectChange (selectedRowKeys) {
      state.selectedRowKeys = selectedRowKeys
    }
    async function handleDeleteProduct (e, id) {
      let single = !!id
      id = id || state.selectedRowKeys.join(',')
      const { code, msg } = await marketing.deletePromoteProduct({ promoteProductId: id })
      if (code === '00000') {
        root.$message.success('删除成功')
        if (!single) {
          state.selectedRowKeys = []
        } else {
          let index = state.selectedRowKeys.findIndex((x) => x.promoteProductId === id)
          state.selectedRowKeys.splice(index, 1)
        }
        handleReset()
      } else {
        root.$message.error(msg || '删除成功')
      }
    }
    function checkRatio (value) {
      const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
      if (!reg.test(value)) {
        return '只能是整数或最多两位小数'
      } else if (value > 100) {
        return '不能超过100'
      } else if (value < 0) {
        return '最少为0'
      } else {
        return ''
      }
    }
    function checkAmount (value) {
      const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
      if (!reg.test(value)) {
        return '只能是整数或最多两位小数'
      } else {
        return ''
      }
    }
    async function setAllBrokerageSubmit () {
      if (!checkSetAllBrokerageData()) return
      state.setAllBrokerageConfirmLoading = true
      const { data, msg, code } = await marketing.setAllBrokerage({
        productIds: state.selectedRowKeys,
        ratios: checkSetAllBrokerageData().map((x, index) => {
          return {
            sharerLevel: index,
            shareRatio: x,
          }
        }),
      })
      state.setAllBrokerageConfirmLoading = false
      if (code === '00000') {
        root.$message.success('设置成功')
        refresh()
        state.setAllBrokerageTableDataError = ''
        state.selectedRowKeys = []
        state.setAllBrokerageTableData = {}
        state.setAllBrokerageModal = false
      } else {
        root.$message.error(msg || '设置失败')
      }
    }
    function checkSetAllBrokerageData () {
      let arr = []
      state.setAllBrokerageTableDataError = ''
      for (let key in state.setAllBrokerageTableData) {
        if (state.setAllBrokerageTableData[key] === '') {
          state.setAllBrokerageTableDataError = `请设置${
            state.setAllBrokerageTableColumns.find((x) => x.dataIndex === key).title
          }比例`
          return false
        } else if (checkRatio(state.setAllBrokerageTableData[key])) {
          state.setAllBrokerageTableDataError =
            state.setAllBrokerageTableColumns.find((x) => x.dataIndex === key).title +
            checkRatio(state.setAllBrokerageTableData[key])
          return false
        } else {
          arr.push(state.setAllBrokerageTableData[key])
        }
      }

      if (arr.length === state.setAllBrokerageTableColumns.length) {
        state.setAllBrokerageTableDataError = ''
        for (let i = 1; i < arr.length - 1; i++) {
          if (Number(arr[i]) > Number(arr[i + 1])) {
            state.setAllBrokerageTableDataError =
              state.setAllBrokerageTableColumns[i + 1].title +
              '不得低于' +
              state.setAllBrokerageTableColumns[i].title
            return false
          }
        }
      }
      return arr
    }
    function checkSetSingleBrokerageData () {
      let allArr = []
      state.setSingleBrokerageTableDataError = ''
      for (let index = 0; index < state.setSingleBrokerageTableData.length; index++) {
        let item = state.setSingleBrokerageTableData[index]
        let arr = []
        for (let key in item) {
          // 按比例校验
          if (state.singleDataBaseForm.shareRatioType === 1 && key.indexOf('ratio') !== -1) {
            if (item[key] === '') {
              state.setSingleBrokerageTableDataError = `请设置${
                item.skuName ? `规格【${item.skuName}】` : ''
              }${state.setSingleBrokerageTableColumns.find((x) => x.dataIndex === key).title}比例`
              return false
            } else if (checkRatio(item[key])) {
              state.setSingleBrokerageTableDataError = item.skuName
                ? '规格【' + item.skuName + '】'
                : '' +
                  state.setSingleBrokerageTableColumns.find((x) => x.dataIndex === key).title +
                  checkRatio(item[key])
              return false
            } else {
              arr.push(item[key])
            }
          } else if (
            state.singleDataBaseForm.shareRatioType === 2 &&
            key.indexOf('amount') !== -1
          ) {
            // 按固定金额校验
            if (item[key] === '') {
              state.setSingleBrokerageTableDataError = `请设置${
                item.skuName ? `规格【${item.skuName}】` : ''
              }${
                state.setSingleBrokerageTableColumns.find((x) => x.dataIndex === key).title
              }固定金额`
              return false
            } else if (checkAmount(item[key])) {
              state.setSingleBrokerageTableDataError = item.skuName
                ? '规格【' + item.skuName + '】'
                : '' +
                  state.setSingleBrokerageTableColumns.find((x) => x.dataIndex === key).title +
                  checkAmount(item[key])
              return false
            } else {
              arr.push(item[key])
            }
          }
        }
        if (
          arr.length ===
          state.setSingleBrokerageTableColumns.filter(
            (x) =>
              x.dataIndex.indexOf(
                state.singleDataBaseForm.shareRatioType === 1 ? 'amount' : 'ratio'
              ) === -1
          ).length -
            3
        ) {
          state.setSingleBrokerageTableDataError = ''
          for (let i = 1; i < arr.length - 1; i++) {
            if (Number(arr[i]) > Number(arr[i + 1])) {
              state.setSingleBrokerageTableDataError = item.skuName
                ? '规格【' + item.skuName + '】'
                : '' +
                  state.setSingleBrokerageTableColumns.filter(
                    (x) =>
                      x.dataIndex.indexOf(
                        state.singleDataBaseForm.shareRatioType === 1 ? 'amount' : 'ratio'
                      ) === -1
                  )[i + 4].title +
                  '不得低于' +
                  state.setSingleBrokerageTableColumns.filter(
                    (x) =>
                      x.dataIndex.indexOf(
                        state.singleDataBaseForm.shareRatioType === 1 ? 'amount' : 'ratio'
                      ) === -1
                  )[i + 3].title // +3 是要  越过 规格名，规格图，价格
              return false
            }
          }
        }
        allArr.push(arr)
      }
      return allArr
    }

    async function setAllBrokerage () {
      state.setAllBrokerageTableDataError = ''
      state.setAllBrokerageModal = true
      // if (!state.setAllBrokerageTableColumns.length) {
      state.setAllBrokerageTableColumns = []
      state.setAllBrokerageLoading = true
      const { data, code, msg } = await marketing.getPromoteLevelList()
      state.setAllBrokerageLoading = false
      if (code === '00000') {
        state.setAllBrokerageTableColumns = data.map((x) => {
          return {
            title: x.levelName,
            width: x.levelName.length * 14 + 36,
            dataIndex: 'shareRatio' + x.level,
            scopedSlots: {
              customRender: 'shareRatio' + x.level,
            },
          }
        })
        data.forEach((x) => {
          state.setAllBrokerageTableData['shareRatio' + x.level] = ''
        })
      } else {
        root.$message.error(msg || '获取数据失败')
      }
      // }
    }
    async function setSingleBrokerage (record) {
      state.setSingleBrokerageModal = true
      state.setSingleBrokerageTableDataError = ''
      state.setSingleBrokerageLoading = true
      const { data, code, msg } = await marketing.getPromoteRateInfo({
        promoteProductId: record.promoteProductId,
      })
      state.setSingleBrokerageLoading = false
      if (code === '00000') {
        state.singleDataBaseForm = {
          name: data.name,
          productId: data.productId,
          promoteProductId: data.promoteProductId,
          shareRatioType: data.shareRatioType,
        }
        createTableColumnAndData(data, record)
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function setSingleBrokerageSubmit () {
      if (!checkSetSingleBrokerageData()) return
      console.log('单个商品佣金设置校验通过')
      let param = {
        ...state.singleDataBaseForm,
        list: [],
      }
      param.list = state.setSingleBrokerageTableData.map((x) => {
        let obj = {
          productSkuId: x.productSkuId,
          skuCode: x.skuCode,
          ratios: [],
        }
        for (let key in x) {
          if (state.singleDataBaseForm.shareRatioType === 1 && key.indexOf('ratio') !== -1) {
            obj.ratios.push({
              sharerLevel: key.replace(/ratio/g, ''),
              shareRatio: x[key],
            })
          } else if (
            state.singleDataBaseForm.shareRatioType === 2 &&
            key.indexOf('amount') !== -1
          ) {
            obj.ratios.push({
              sharerLevel: key.replace(/amount/g, ''),
              shareRatio: x[key],
            })
          }
        }
        return obj
      })
      state.setSingleBrokerageConfirmLoading = true
      const { data, msg, code } = await marketing.setSingleBrokerage(param)
      state.setSingleBrokerageConfirmLoading = false
      if (code === '00000') {
        root.$message.success('设置成功')
        handleReset()
        state.setSingleBrokerageTableDataError = ''
        state.setSingleBrokerageTableData = []
        state.setSingleBrokerageModal = false
      } else {
        root.$message.error(msg || '设置失败')
      }
    }
    function createTableColumnAndData (data, record) {
      let columns = []
      let dataSource = []
      dataSource = data.list.map((x) => {
        let obj = {
          productSkuId: x.productSkuId,
          skuCode: x.skuCode,
          skuPic: x.skuPic || record.productImg,
          skuName: x.skuName,
          price: x.price,
        }
        x.ratios.forEach((y) => {
          if (y.shareRatioType === 1) {
            obj['ratio' + y.sharerLevel] = y.shareRatio
            obj['amount' + y.sharerLevel] = ''
          } else {
            obj['ratio' + y.sharerLevel] = ''
            obj['amount' + y.sharerLevel] = y.shareRatio
          }
        })
        return obj
      })
      columns = [
        {
          title: '规格',
          width: 100,
          dataIndex: 'skuName',
          scopedSlots: {
            customRender: 'skuName',
          },
        },
        {
          title: '规格图',
          width: 100,
          dataIndex: 'skuPic',
          scopedSlots: {
            customRender: 'skuPic',
          },
        },
        {
          title: '单价',
          dataIndex: 'price',
          width: 100,
          scopedSlots: {
            customRender: 'price',
          },
        },
      ]
      data.list[0].ratios.forEach((item, index) => {
        columns.push(
          {
            title: item.levelName,
            width: item.levelName.length * 14 + 36,
            dataIndex: 'ratio' + item.sharerLevel,
            scopedSlots: {
              customRender: 'ratio' + item.sharerLevel,
            },
          },
          {
            title: item.levelName,
            width: item.levelName.length * 14 + 36,
            dataIndex: 'amount' + item.sharerLevel,
            scopedSlots: {
              customRender: 'amount' + item.sharerLevel,
            },
          }
        )
      })
      state.setSingleBrokerageTableColumns = columns
      state.setSingleBrokerageTableData = dataSource
    }
    return {
      ...toRefs(state),
      formRef,
      queryRef,
      columns,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleReset,
      changeTablePage,
      handleDeleteProduct,
      onSelectChange,
      setAllBrokerageSubmit,
      setAllBrokerage,
      checkSetAllBrokerageData,
      setSingleBrokerage,
      setSingleBrokerageSubmit,
      checkSetSingleBrokerageData,
    }
  },
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
.goods-info {
  display: flex;
  align-items: center;
  &.goods {
    cursor: pointer;
  }
  .goods-img {
    flex: none;
    margin-right: 12px;
  }
  .goods-name {
    flex: 0 1 auto;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066ff;
    &.shop-name {
      color: #0066ff;
      cursor: pointer;
    }
  }
}
.table-operations ::v-deep .ant-btn {
  padding: 0 5px;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
.search-box {
  display: flex;
  align-items: center;
  margin: 12px 16px;
  justify-content: flex-end;
}
.product-modal ::v-deep .ant-modal-body {
  padding: 0 24px 12px;
}
.table-goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    width: 60px;
  }
  .goods-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.product-table ::v-deep td {
  padding: 4px 12px;
}
.tip {
  margin-top: 12px;
  color: red;
  font-size: 12px;
}
</style>
